








import { computed, defineComponent } from '@vue/composition-api'
import LabeledText from './LabeledText.vue'

export default defineComponent({
  components: { LabeledText },
  props: {
    label: { type: String, default: null },
    targetDeviationPercentage: { type: Number, default: null }
  },
  setup(props) {
    const formattedTargetDeviationPercentage = computed(() => {
      const sign = props.targetDeviationPercentage > 0 ? '+' : ''
      return props.targetDeviationPercentage || props.targetDeviationPercentage === 0
        ? `${sign}${Math.round(props.targetDeviationPercentage)}%`
        : '-'
    })
    const targetDeviationPercentageClass = computed(() => {
      if (props.targetDeviationPercentage === null) {
        return ''
      }
      const roundedPercentage = Math.round(props.targetDeviationPercentage)
      if (roundedPercentage >= 0) {
        return 'target-deviation-ok'
      }
      return 'target-deviation-error'
    })

    return { formattedTargetDeviationPercentage, targetDeviationPercentageClass }
  }
})
