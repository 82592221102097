var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Invoices",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.loading,
          expression: "!loading"
        }
      ],
      ref: "containerRef",
      attrs: {
        invoices: _vm.invoices,
        computedAmountToInvoice: _vm.computedAmountToInvoice,
        title: _vm.$t("finance/shipper-invoices-title")
      },
      on: { download: _vm.downloadInvoice }
    },
    [
      _vm.shipperInvoicesUploadEnabled
        ? _c("Button", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.isCompact
                  ? _vm.$t("finance/carrier-invoices-add-button-label")
                  : undefined,
                expression:
                  "isCompact ? $t('finance/carrier-invoices-add-button-label') : undefined"
              }
            ],
            staticClass: "add-invoice-button",
            attrs: {
              category: _vm.isCompact ? "tertiary" : "secondary",
              text: _vm.isCompact
                ? ""
                : _vm.$t("finance/carrier-invoices-add-button-label"),
              materialIcon: "add",
              "data-test": "add-invoice"
            },
            on: { click: _vm.addInvoice }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.shipperInvoicesUploadEnabled
        ? _c("AddInvoiceModal", {
            ref: "addInvoiceModal",
            attrs: { orderId: _vm.orderId, invoiceType: "SHIPPER" },
            on: { added: _vm.invoiceAdded }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }