import axios, {AxiosError, AxiosRequestConfig} from 'axios'
import {appOptions, ICommonHeaders} from '/@/app-options'

export const api = axios.create()
export const costCentreServiceApi = axios.create()
export const fleetServiceApi = axios.create()
export const exchangeServiceApi = axios.create()
export const podUploadServiceApi = axios.create()
export const claimsAccesorialsClient = axios.create()

api.interceptors.request.use(
  addAuthenticationInterceptor,
  (error) => Promise.reject(error)
)
costCentreServiceApi.interceptors.request.use(
  constCentreAuthInterceptor,
  (error) => Promise.reject(error)
)
fleetServiceApi.interceptors.request.use(
  fleetAuthInterceptor,
  (error) => Promise.reject(error)
)
podUploadServiceApi.interceptors.request.use(
  podUploadAuthInterceptor,
  (error) => Promise.reject(error)
)

exchangeServiceApi.interceptors.request.use(
  exchangeAuthInterceptor,
  (error) => Promise.reject(error)
)

claimsAccesorialsClient.interceptors.request.use(
  claimsAccessorialsAuthInterceptor,
  (error) => Promise.reject(error)
)

claimsAccesorialsClient.interceptors.response.use(
  function (response) {
    return response
  },
  function (error: AxiosError) {
    throw error
  }
)

async function claimsAccessorialsAuthInterceptor(config: AxiosRequestConfig) {
  const headers: ICommonHeaders = await appOptions.getCommonHeaders()
  if (
      !headers.Authorization ||
      !headers['X-Org-Id'] ||
      !headers['X-Org-Type']
  ) {
    throw new Error('[Claims & Accessorials] Auth token is null')
  }

  if (!appOptions.claimsUrl) {
    throw Error('[Claims & Accessorials] claims url is not defined.')
  }

  config.baseURL = appOptions.claimsUrl
  config.headers = {
    ...config.headers,
    Authorization: headers.Authorization,
    'x-org-id': headers['X-Org-Id'],
    'x-org-type': headers['X-Org-Type'],
  }
  return config
}

async function constCentreAuthInterceptor(config: AxiosRequestConfig) {
  if (!appOptions.costCentreURL) {
    throw Error('[Finance Frontend] costCentreUrl is not defined.')
  }
  config.baseURL = appOptions.costCentreURL
  config.headers = {
    ...config.headers,
    'authorization': await appOptions.getAuthorizationHeaderWithParams(),
    'Authorization-Host': appOptions.authorizationServiceURL
  }

  return config
}

async function fleetAuthInterceptor(config: AxiosRequestConfig) {
  if (!appOptions.fleetServiceURL) {
    throw Error('[Finance Frontend] fleet service Url is not defined.')
  }
  config.baseURL = appOptions.fleetServiceURL
  config.headers = {
    ...config.headers,
    'authorization': await appOptions.getAuthorizationHeaderWithParams(),
    'Authorization-Host': appOptions.authorizationServiceURL
  }

  return config
}

async function exchangeAuthInterceptor(config: AxiosRequestConfig) {
  if (!appOptions.exchangeServiceURL) {
    throw Error('[Finance Frontend] exchange service Url is not defined.')
  }
  config.baseURL = appOptions.exchangeServiceURL
  config.headers = {
    ...config.headers,
    'authorization': await appOptions.getAuthorizationHeaderWithParams(),
    'Authorization-Host': appOptions.authorizationServiceURL
  }

  return config
}

async function podUploadAuthInterceptor(config: AxiosRequestConfig) {
  if (!appOptions.podUploadServiceURL) {
    throw Error('[Finance Frontend] pod upload service Url is not defined.')
  }

  config.baseURL = appOptions.podUploadServiceURL
  config.headers = {
    ...config.headers,
    'authorization': await appOptions.getAuthorizationHeaderWithParams('https://api.cloud.sennder.com/pod-upload', 'pod:manage pod:read-all'),
    'Authorization-Host': appOptions.authorizationServiceURL,
    'x-tenant': appOptions.tenant
  }

  return config
}

async function addAuthenticationInterceptor(config: AxiosRequestConfig) {
  if (!appOptions.baseURL)
    throw Error('[Finance Frontend] baseURL is not defined.')

  config.baseURL = appOptions.baseURL
  config.headers = {
    ...config.headers,
    authorization: await appOptions.getAuthorizationHeaderWithParams()
  }

  return config
}
