















































import { defineComponent, toRef, PropType, computed } from '@vue/composition-api'
import DocumentManagementSection from './document-management/DocumentManagementSection.vue'
import CustomerChargesBreakdown from './breakdowns/CustomerChargesBreakdown.vue'
import ShipperInvoices from './invoices/ShipperInvoices.vue'
import CostCentreOrder from './cost-centre/CostCentreOrder.vue'
import useOrderPaymentStatus from '/@/compositions/useOrderPaymentStatus'
import Claims from '/@/components/claims/Claims.vue'
import { FinanceOrder, appOptions } from '/@/app-options'
import OctopusPaymentsWidgetPortal from './microfrontends/OctopusPaymentsWidgetPortal.vue'

export default defineComponent({
  components: {
    DocumentManagementSection,
    CustomerChargesBreakdown,
    Claims,
    CostCentreOrder,
    ShipperInvoices,
    OctopusPaymentsWidgetPortal
  },
  props: {
    order: { type: Object as PropType<FinanceOrder>, required: true },
    compact: { type: Boolean, default: false }
  },
  setup(props) {
    const { paymentInfo, fetchPaymentInformation } = useOrderPaymentStatus(
      toRef(props.order, 'id')
    )

    const showSettlementCustomerPrice = computed(() =>
      appOptions.useFeatureFlag().isActive('ENABLE_SHIPPER_PRICE_WIDGET').value
    )

    const isEnableClaimAccessorial = computed(() => {
      return appOptions.useFeatureFlag().isActive('ENABLE_CLAIM_ACCESSORIAL').value
    })

    fetchPaymentInformation()

    return {
      paymentInfo,
      costCentreEnabled: appOptions.costCentreEnabled,
      showSettlementCustomerPrice,
      isEnableClaimAccessorial
    }
  }
})
