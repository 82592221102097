import { api } from './api'
import {
  transformKeysToSnakeCase,
  transformKeysToCamelCase
} from '/@/services/casing'
import { ExtraChargeType, FinanceOrderLog, PresignedUpload } from './types'
import {
  AddCarrierExtraCharge,
  CarrierCharges,
  Invoice,
  FinanceInvoiceData,
  OrderPaymentInformation,
  CustomerCharges,
  UnifiedCharges
} from './types'
import { CarrierBilling } from '/@/services/types'
import { AxiosResponse } from 'axios'

const idSorting = (a: { id: number }, b: { id: number }) => a.id - b.id

export const submitDieselFloater = async (
  { companyId, percentage, month, year }: 
  { companyId: number, percentage: number, month: number, year: number}): Promise<AxiosResponse> =>
  api.post(
    '/finance/commands/set-diesel-floater',
    transformKeysToSnakeCase({ companyId, percentage, month, year })
  )

export const fetchOrderPaymentInformation = async (orderId: number): Promise<OrderPaymentInformation> => {
  const response = await api.get(
    '/finance/queries/get-payment-information',
    { params: transformKeysToSnakeCase({ orderId }) }
  )
  return transformKeysToCamelCase<OrderPaymentInformation>(response.data)
}

export const fetchInvoices = async (orderId: number): Promise<FinanceInvoiceData> => {
  const response = await api.get(
    '/finance/queries/get-invoices',
    { params: transformKeysToSnakeCase({ orderId }) }
  )
  return transformKeysToCamelCase<FinanceInvoiceData>(response.data)
}

export const fetchInvoiceDownloadUrl = async (invoiceId: number, isCarrierInvoice: boolean): Promise<string> => {
  const params = isCarrierInvoice
    ? { carrierInvoiceId: invoiceId }
    : { shipperInvoiceId: invoiceId }
  const response = await api.get(

    '/finance/queries/get-invoice-file',
    { params: transformKeysToSnakeCase(params) }
  )
  return transformKeysToCamelCase<{ url: string }>(response.data).url
}

export const fetchCustomerExtraCharges = async (orderId: number) => {
  const response = await api.get(
    '/finance/queries/get-customer-extra-charges',
    { params: transformKeysToSnakeCase({ orderId }) }
  )
  if (!response) return null
  const customerCharges = transformKeysToCamelCase<CustomerCharges>(response.data)
  const unifiedCharges: UnifiedCharges = {
    orderId: customerCharges.orderId,
    baseCharge: {
      ...customerCharges.basePriceCharge,
      chargeCorrections: customerCharges.basePriceCharge?.chargeCorrections.sort(
        idSorting
      )
    },
    extraCharges: customerCharges.customerExtraCharges.sort(idSorting)
  }
  return unifiedCharges
}

export const fetchCarrierExtraCharges = async (orderId: number) => {
  const response = await api.get(
    '/finance/queries/get-carrier-extra-charges',
    { params: transformKeysToSnakeCase({ orderId }) }
  )
  if (!response) return null
  const carrierCharges = transformKeysToCamelCase<CarrierCharges>(response.data)
  const unifiedCharges: UnifiedCharges = {
    orderId: carrierCharges.orderId,
    baseCharge: {
      ...carrierCharges.baseCostCharge,
      chargeCorrections: carrierCharges.baseCostCharge?.chargeCorrections.sort(idSorting)
    },
    extraCharges: carrierCharges.carrierExtraCharges.sort(idSorting)
  }
  return unifiedCharges
}

export const saveCarrierInvoice = async (invoice: Invoice) => {
  const response = await api.post(
    '/finance/carrier_invoices/',
    transformKeysToSnakeCase({
      number: invoice.number,
      order: invoice.orderId,
      amount: invoice.amount,
      issueDate: invoice.issueDate,
      isCreditNote: invoice.isCreditNote,
      filePath: invoice.filePath
    })
  )
  if (!response) return null
  return transformKeysToCamelCase<Invoice>(response.data)
}

export const saveShipperInvoice = async (invoice: Invoice) => {
  const response = await api.post(
    '/finance/commands/create-shipper-invoice',
    transformKeysToSnakeCase({
      number: invoice.number,
      orderId: invoice.orderId,
      amount: invoice.amount,
      issueDate: invoice.issueDate,
      isCreditNote: invoice.isCreditNote,
      filePath: invoice.filePath,
    })
  )
  if (!response) return null
  return transformKeysToCamelCase<Invoice>(response.data)
}

export const cancelInvoiceById = async (invoiceId: number) => {
  const response = await api.patch(
    `/finance/carrier_invoices/${invoiceId}/`,
    transformKeysToSnakeCase({
      isCancelled: true
    })
  )
  if (!response) return null
  return transformKeysToCamelCase<Invoice>(response.data)
}

export const addExtraCharge = async (
  extraCharge: AddCarrierExtraCharge,
  extraChargeType: ExtraChargeType
) => {
  await api.post(
    `/ordering/${extraChargeType.toLowerCase()}_extra_charges/`,
    transformKeysToSnakeCase(extraCharge)
  )
}

export const addBaseAmountCorrection = async (
  orderId: number,
  correctionAmount: number,
  notes: string,
  extraChargeType: ExtraChargeType
) => {
  let endpointUrl = '/ordering/'
  if (extraChargeType === 'CUSTOMER') endpointUrl += 'add_correction_for_base_price'
  if (extraChargeType === 'CARRIER') endpointUrl += 'add_correction_for_base_cost'
  await api.post(
    endpointUrl,
    transformKeysToSnakeCase({
      orderId,
      correctionAmount,
      notes,
      extraChargeType
    })
  )
}

export const addExtraChargeCorrection = async (
  extraChargeId: number,
  correctionAmount: number,
  notes: string,
  extraChargeType: ExtraChargeType
) => {
  await api.post(
    '/ordering/add_correction_for_extra_charge',
    transformKeysToSnakeCase({
      extraChargeId,
      correctionAmount,
      notes,
      extraChargeType
    })
  )
}

export const getSalesforceUrl = async (companyId: number) => {
  const response = await api.get(
    `/finance/queries/get-salesforce-url/${companyId}`
  )
  if (!response) return null
  return transformKeysToCamelCase<{ salesforceLink: string }>(response.data)
}

export const fetchCarrierBillingInformation = async (
  carrierId: number
): Promise<CarrierBilling> => {
  const response = await api.get(
    `/finance/queries/carriers/get-billing-information/${carrierId}`
  )
  return transformKeysToCamelCase(response.data)
}

export const fetchOrdersAggregation = async (filters: unknown) => {
  const response = await api.post(
    '/finance/queries/orders-aggregation',
    transformKeysToSnakeCase({ filterDictionary: filters })
  )
  if (!response) return null
  return transformKeysToCamelCase(response.data)
}

export const fetchFinanceOrderLogs = async (orderId: number) => {
  const response = await api.get(
    '/finance/queries/get-order-logs',
    { params: transformKeysToSnakeCase({ orderId }) }
  )
  if (!response) return null
  return transformKeysToCamelCase<FinanceOrderLog[]>(response.data)
}

export const fetchPresignedUpload = async (
  fileName: string
): Promise<PresignedUpload> => {
  const response = await api.get(
    '/finance/queries/get-invoice-document-presigned-upload-url',
    { params: transformKeysToSnakeCase({ fileName }) }
  )
  if (!response) return null
  // Dont transform fields object to camelCase because those are metadata for S3
  return {
    url: response.data.url,
    fields: response.data.fields,
    uploadDirectory: response.data.upload_directory
  }
}