var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.order && _vm.order.id
    ? _c(
        "div",
        {
          class: {
            "finance-order": true,
            "finance-order--full-screen": _vm.enableFullScreen
          }
        },
        [
          _vm.financeView
            ? _c("FinanceOrderHeader", {
                staticClass: "finance-order__header",
                attrs: {
                  order: _vm.order,
                  view: _vm.financeView,
                  spotCosting: _vm.spotCosting,
                  targetDeviationPercentage: _vm.targetDeviationPercentage
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content-wrapper finance-order__wrapper" },
            [
              _vm.financeView
                ? _c("FinanceOrderSidebar", {
                    staticClass: "finance-order__sidebar",
                    attrs: {
                      order: _vm.order,
                      stops: _vm.orderStops,
                      view: _vm.financeView
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.financeView
                ? _c(
                    "transition",
                    { attrs: { name: "fade", mode: "out-in" } },
                    [
                      _c(
                        "FinanceOrderMainWrapper",
                        {
                          key: _vm.financeView,
                          staticClass: "finance-order__main"
                        },
                        [
                          _vm.financeView === "AP"
                            ? _c("FinanceOrderMainCarrier", {
                                attrs: {
                                  order: _vm.order,
                                  compact: _vm.sidebarIsVisible
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.financeView === "AR"
                            ? _c("FinanceOrderMainCustomer", {
                                attrs: {
                                  order: _vm.order,
                                  compact: _vm.sidebarIsVisible
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          !_vm.loading
            ? _c("OrderActivitySidebar", {
                attrs: {
                  orderId: _vm.order.id,
                  currentUserId: _vm.currentUserId
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("FinanceOrderFooter", {
            attrs: {
              orderId: _vm.order.id,
              orderState: _vm.order.state,
              accountManager: _vm.accountManager,
              partnerManager: _vm.partnerManager,
              spotBidder: _vm.spotBidder,
              juniorAccountManager: _vm.juniorAccountManager,
              orderRegularity: _vm.order.regularity
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }