




























import {
  defineComponent,
  toRef,
  ref,
  onBeforeMount,
  computed,
  watchEffect,
watch
} from '@vue/composition-api'
import useInvoices from '/@/compositions/useInvoices'
import {
  formatMoneyWithZeroForNull,
  formatDate
} from '/@/filters/filters'
import { Invoice } from '/@/services/types'
import { ParsedInvoice } from './types'
import Invoices from './Invoices.vue'
import AddInvoiceModal from './AddInvoiceModal.vue'
import { Button } from '@sennder/plankton'
import useResizeObserver from '/@/compositions/useResizeObserver'
import { fetchInvoiceDownloadUrl } from '/@/services/finance-service'
import { appOptions } from '/@/app-options'

export default defineComponent({
  components: {
    Invoices,
    AddInvoiceModal,
    Button
  },
  props: {
    orderId: {
      type: Number,
      required: true
    },
    compact: { type: Boolean, default: false }
  },
  setup(props, context) {
    const addInvoiceModal = ref()
    const loading = ref(true)
    const orderId = toRef(props, 'orderId')
    const containerRef = ref()
    const isCompact = ref(false)

    const {
      order
    } = appOptions.useOrder()
    const isCancelled = computed(() => order.value.isCancelled)
    
    const { loadShipperInvoices, shipperInvoiceAggregate } = useInvoices(orderId)

    watch([orderId, isCancelled], (newValues, prevValues) => {
      if (newValues[0] === prevValues[0] && newValues[1] === prevValues[1]) { return }
      updateInvoices()
    })

    const updateInvoices = async () => {
      loading.value = true
      await loadShipperInvoices()
      loading.value = false
    }

    onBeforeMount(updateInvoices)

    const invoices = computed<ParsedInvoice[]>(() => {
      return shipperInvoiceAggregate.value.shipperInvoices.map(
        (invoice: Invoice) => ({
          netCost: formatMoneyWithZeroForNull(invoice.amount),
          type: invoice.isCreditNote
            ? context.root.$t('finance/shipper-invoices-type-credit-note-label') as string
            : context.root.$t('finance/shipper-invoices-type-invoice-label') as string,
          issueDate: formatDate(invoice.issueDate),
          number: invoice.number,
          isCancelled: invoice.isCancelled,
          id: invoice.id,
          filePath: invoice.filePath
        })
      )
    })

    const computedAmountToInvoice = computed(
      () => shipperInvoiceAggregate.value.remainingAmountToInvoiceForShipper
    )

    const downloadInvoice = async (invoice: ParsedInvoice) => {
      window.open(await fetchInvoiceDownloadUrl(invoice.id, false))
    }

    const containerElRef = computed(() => containerRef.value?.$el)
    const { resizeState } = useResizeObserver(containerElRef)

    watchEffect(() => {
      const { width } = resizeState.contentRect
      isCompact.value = (props.compact || width < 540)
    })

    const invoiceAdded = () => {
      loadShipperInvoices()
    }
    const addInvoice = () => {
      addInvoiceModal.value.open()
    }

    return {
      invoices,
      loading,
      computedAmountToInvoice,
      containerRef,
      isCompact,
      downloadInvoice,
      invoiceAdded,
      addInvoice,
      addInvoiceModal,
      shipperInvoicesUploadEnabled: appOptions.shipperInvoicesUploadEnabled,
    }
  }
})
