var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "finance-order-main-customer" },
    [
      _vm.costCentreEnabled
        ? _c("CostCentreOrder", {
            attrs: {
              orderId: _vm.order.id,
              orderIdForStaff: _vm.order.idForStaff,
              carrierId: _vm.order.carrierCompanyId,
              partnerManagerId: _vm.order.partnerManagerId
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.showSettlementCustomerPrice
        ? _c("CustomerChargesBreakdown", {
            staticClass: "finance-order-main-customer__breakdown",
            attrs: {
              orderId: _vm.order.id,
              basePrice: _vm.order.basePrice,
              totalNetPrice: _vm.order.totalNetPrice,
              isCancelled: _vm.order.isCancelled,
              paymentInfo: _vm.paymentInfo,
              compact: _vm.compact,
              canAddSurcharge: "",
              refreshInvoicesOnNewCharges: ""
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showSettlementCustomerPrice
        ? _c("OctopusPaymentsWidgetPortal", {
            staticClass: "finance-order-main-customer__breakdown",
            attrs: {
              orderId: _vm.order.id,
              "data-test": "octopus-payments-widget-portal"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("ShipperInvoices", {
        attrs: { orderId: _vm.order.id, compact: _vm.compact }
      }),
      _vm._v(" "),
      !_vm.isEnableClaimAccessorial
        ? [
            _c("Claims", {
              staticClass: "finance-order-main-customer__claims",
              attrs: {
                orderId: _vm.order.id,
                "data-test": "finance-order-main-customer__finance-test"
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("DocumentManagementSection", {
        attrs: {
          orderId: _vm.order.id,
          editable: false,
          carrierId: _vm.order.carrierCompanyId
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }