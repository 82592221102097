import { get } from 'lodash'
import { transformKeysToCamelCase } from './casing'
import { api, podUploadServiceApi } from './api'

import { Timestamp } from '@sennder/plankton'
import { PaymentReadyness } from './types'

// TODO: receive value from Octopus
const DEFAULT_TIMEZONE = 'Europe/Berlin'

export type ProofOfDeliveryOrigin =
  | 'landing_page'
  | 'sennder_frontend'
  | 'driver_app'
  | 'octopus'
  | 'orcas'
export type ProofOfDeliveryState = 'UPLOADED' | 'SUBMITTED' | 'APPROVED' | 'REJECTED'
export type RejectionReason = {
  key: string
  read_only: boolean
}
export interface ProofOfDelivery {
  fileName: string
  fileSize: number
  state: ProofOfDeliveryState
  created: Date
  id: string
  origin: ProofOfDeliveryOrigin
  rejectionReason: string
  rejectionAdditionalInformation: string
  rejectedAt: typeof Timestamp
}

const fetchAllProofOfDeliveryDocumentsByOrderId = async (
  orderId: number
): Promise<Array<ProofOfDelivery>> => {
  const response = await podUploadServiceApi.get(
    `/finance/orders/${orderId}/documents`
  )

  return get(response, 'data.data', []).map(transformNewPodDocument)
}

const transformPodDocument = (
  podDocument:
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { file_name: any; file_size: any; state: any; created: string | number | Date; id: any; origin: any; rejection_reason: any; rejection_additional_information: any; rejected_at: any }): ProofOfDelivery => ({
  fileName: podDocument.file_name,
  fileSize: podDocument.file_size,
  state: podDocument.state,
  created: new Date(podDocument.created),
  id: podDocument.id,
  origin: podDocument.origin.toLowerCase() as ProofOfDeliveryOrigin,
  rejectionReason: podDocument.rejection_reason,
  rejectionAdditionalInformation: podDocument.rejection_additional_information,
  rejectedAt: new Timestamp(podDocument.rejected_at, DEFAULT_TIMEZONE)
})

const transformNewPodDocument = (
  podDocument: 
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { file_name: any; file_size: any; state: any; created: string | number, created_at: string | number | Date; id: any; origin: any; rejection_reason: any; rejection_additional_info: any; rejected_at: any }): ProofOfDelivery => ({
    ...transformPodDocument({ 
      ...podDocument, 
      rejection_additional_information: podDocument.rejection_additional_info 
    }),
    created: new Date(podDocument.created_at),
  }
)

const submitMissingDocumentRequest = async (orderId: number, documentType: string) => {
  return podUploadServiceApi.post(
    `/finance/orders/${orderId}/missing-documents`,
    { type: documentType.toLowerCase() }
  )
}

const submitPaymentStart = async (orderId: number) => {
  const URL = `/proof-of-delivery/commands/start-payment/${orderId}`
  await api.post(URL)
}

const fetchPaymentReadinessDetails = async (orderId: number): Promise<PaymentReadyness> => {
  const response = await podUploadServiceApi.get(
    `/finance/queries/payment-can-be-started/${orderId}`
  )

  return transformKeysToCamelCase(get(response, 'data'))
}

export {
  fetchAllProofOfDeliveryDocumentsByOrderId,
  submitMissingDocumentRequest,
  fetchPaymentReadinessDetails,
  submitPaymentStart
}
