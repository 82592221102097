


















































































import {
  onBeforeMount,
  ref,
  toRef,
  computed,
  watch,
  defineComponent,
  Ref,
  PropType,
} from '@vue/composition-api'
import { appOptions } from '/@/app-options'
import RequestMissingDocumentModal from './RequestMissingDocumentModal.vue'
import StartPaymentModal from './StartPaymentModal.vue'
import usePaymentActions from '../compositions/usePaymentActions'
import useOrderPaymentStatus from '../compositions/useOrderPaymentStatus'
import { hasHadState } from '/@/order-states'
import useProofOfDeliveries from '/@/compositions/useProofOfDeliveries'
import { ExtendedPerson, OrderState, OrderRegularity } from '/@/app-options'
import { Button, RequirementsPopover } from '@sennder/plankton'
import StartPaymentPortal from './microfrontends/StartPaymentPortal.vue'
import {
  subscribe,
  onPodApprovedOrRejected,
  MicrofrontendEvents,
} from '@sennder/senn-node-microfrontend-event-bus'

export default defineComponent({
  components: {
    OrderStageFooter: () => Promise.resolve(appOptions.components.OrderStageFooter),
    RequestMissingDocumentModal,
    StartPaymentModal,
    Button,
    RequirementsPopover,
    StartPaymentPortal
},
  props: {
    orderId: {
      type: Number,
      required: true,
    },
    orderState: {
      type: String as PropType<OrderState>,
      default: 'NEW',
    },
    accountManager: {
      type: Object as PropType<ExtendedPerson>,
      default: null,
    },
    juniorAccountManager: {
      type: Object as PropType<ExtendedPerson>,
      default: null,
    },
    partnerManager: {
      type: Object as PropType<ExtendedPerson>,
      default: null,
    },
    spotBidder: {
      type: Object as PropType<ExtendedPerson>,
      default: null,
    },
    orderRegularity: {
      type: Object as PropType<OrderRegularity>,
      default: null,
    },
  },
  setup(props, context) {
    const requestMissingDocumentModal = ref(null)
    const startPaymentModal = ref(null)
    const orderId = toRef(props, 'orderId')
    const orderState = toRef(props, 'orderState') as Ref<OrderState>
    const paymentInProgress = ref(false)

    const isRequestingDocsPossible = computed(() =>
      hasHadState(orderState.value, 'EXECUTED')
    )

    const showPaymentsWidgetStartPayment = computed<boolean>(() => {
      return appOptions.useFeatureFlag().isActive('START_PAYMENT_MEXIT').value
    })

    const {
      isStartPaymentBtnDisabled,
      paymentRequirements,
      isPopoverVisible,
      hoverStartPaymentBtn,
      unhoverStartPaymentBtn,
      startPayment,
      getPaymentReadinessDetails,
    } = usePaymentActions({
      orderId,
      $t: context.root.$t.bind(context.root),
    })

    const { paymentInfo, fetchPaymentInformation } =
      useOrderPaymentStatus(orderId)
    onBeforeMount(fetchPaymentInformation)
    const isEligibleForAutomaticCreditNotes = computed(
      () => paymentInfo.value?.isEligibleForAutomaticCreditNotes
    )

    const startPaymentProcess = async () => {
      try {
        paymentInProgress.value = true
        await startPayment()
        await fetchPaymentInformation()
        closeStartPaymentModal()
      } finally {
        paymentInProgress.value = false
      }
    }

    const openModalToRequestMissingDocument = () => {
      return requestMissingDocumentModal.value.open()
    }
    const openStartPaymentModal = () => {
      return startPaymentModal.value.open()
    }
    const closeStartPaymentModal = () => {
      return startPaymentModal.value.close()
    }

    const { proofOfDeliveries } = useProofOfDeliveries(orderId)


    onBeforeMount(async () => {
      subscribe<onPodApprovedOrRejected>(
        MicrofrontendEvents.onPodApprovedOrRejected,
        async () => {
          setTimeout(async function () {
            await getPaymentReadinessDetails()
          }, 1000)
        }
      )
    })

    // Watch for changes in documents to check
    // if we can start the payment already
    watch(proofOfDeliveries, getPaymentReadinessDetails)

    return {
      requestMissingDocumentModal,
      startPaymentModal,
      isStartPaymentBtnDisabled,
      paymentRequirements,
      isPopoverVisible,
      hoverStartPaymentBtn,
      unhoverStartPaymentBtn,
      openModalToRequestMissingDocument,
      openStartPaymentModal,
      isEligibleForAutomaticCreditNotes,
      startPaymentProcess,
      closeStartPaymentModal,
      isRequestingDocsPossible,
      paymentInProgress,
      showPaymentsWidgetStartPayment,
    }
  },
})
